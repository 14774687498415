import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ pb: 2 }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://boxfishsoftware.com/">
        Boxfish Software Ltd
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "#162328",
        borderTop: 1,
        borderColor: "divider",
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ pt: 1 }}
        >
          <Link
            href="https://github.com/BoxfishSoftware"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "primary.contrastText",
              ":hover": { color: "#FBEA8C" },
            }}
          >
            <GitHubIcon sx={{ mr: 1 }} />
          </Link>
          <Link
            href="https://www.linkedin.com/company/boxfish-software/"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: "primary.contrastText",
              ":hover": { color: "#FBEA8C" },
            }}
          >
            <LinkedInIcon />
          </Link>
        </Box>
        <Copyright />
      </Container>
    </Box>
  );
}
