import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import BoxfishLogoSquare from "../../images/boxfish-logo-square.png";
import Container from "@mui/material/Container";

const sections = [
  { title: "About", url: "#" },
  { title: "Consultancy", url: "#" },
  { title: "Training", url: "#" },
  { title: "Blog", url: "#" },
  { title: "Contact", url: "#" },
];

/*

colours:
  blue:
    #36506B (one from logo)
    #364f6b (darker?)
    #47688d (lighter?)

  yellow:
    #FBEA8C (from logo)
    #FCE389 (nicest with blue)
    #fbd758 (bolder yellow)
    #FBE288 (lighter yellow)
    #E8CD7E (dark border yellow)
    not sure:
    123456 
    FCEFBC - very pale

  #ffffff 

*/

export default function Header({ showMenu }: { showMenu: boolean }) {
  return (
    <AppBar
      position="static"
      color="primary"
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        backgroundImage: "none",
        backgroundColor: "#1D2A35",
      }}
      enableColorOnDark
    >
      <Container maxWidth="lg">
        <Toolbar>
          <img
            src={BoxfishLogoSquare}
            alt="Boxfish Software Logo"
            style={{ height: "50px", width: "50px" }}
          />
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            noWrap
            sx={{ flex: 1, ml: 1 }}
          >
            Boxfish Software
          </Typography>
          {showMenu &&
            sections.map((section) => (
              <Button
                key={section.title}
                href={section.url}
                size="large"
                sx={{
                  p: 1,
                  flexShrink: 0,
                  color: "primary.contrastText",
                  ":hover": { color: "#FBEA8C" },
                  mr: 1,
                }}
              >
                {section.title}
              </Button>
            ))}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
