import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import LandingTopBox from "./components/LandingTopBox";
import Main from "./components/Main";

// good: 4 (about page),  7 (software), 6(runner up),
import HeroImg from "./images/heroes/hero-7.jpg";
import Box from "@mui/material/Box";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Email from "@mui/icons-material/Email";
import CardHeader from "@mui/material/CardHeader";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import LightbulbCircleIcon from "@mui/icons-material/LightbulbCircle";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Obfuscate from "react-obfuscate";

declare module "@mui/material/styles" {
  interface Palette {
    boxfishBlue: Palette["primary"];
    boxfishYellow: Palette["primary"];
  }

  interface PaletteOptions {
    boxfishBlue: PaletteOptions["primary"];
    boxfishYellow: PaletteOptions["primary"];
  }
}

// #36506B
// green: #DCFC8A (dark: #CDFB58), red: #FCAA8A
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#36506B",
    },
    boxfishBlue: {
      main: "#36506B",
      contrastText: "#fff",
    },
    boxfishYellow: {
      main: "#FCE38A",
      contrastText: "#1D2A35",
      dark: "#FBD758",
    },
    background: {
      default: "#1D2A35",
    },
  },
});

const ExtraPages = () => {
  return (
    <>
      <Typography variant="body1">
        Whether you are a startup looking to build your first software solution
        or an established company looking to optimize your current technology
        stack, we are here to help. Our team will work closely with you to
        understand your specific needs and develop a customized solution that
        meets your requirements and exceeds your expectations.
      </Typography>
      <Typography variant="h5">
        We offer a range of services to suit businesses of any size:
      </Typography>
      <Typography variant="body1">
        We pride ourselves on our commitment to quality and our dedication to
        customer satisfaction. We understand that our success is directly tied
        to the success of our clients, which is why we work tirelessly to
        deliver the highest quality solutions on time and within budget.
      </Typography>
      <Typography variant="body1">
        We are a team of software engineers, data scientists, and biologists who
        are passionate about building software that makes a difference. We are
        committed to helping our clients achieve their goals and are excited to
        work with you to develop the next generation of biotech software.
      </Typography>
      <LandingTopBox />
      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Main title="From the firehose" />
      </Grid>
    </>
  );
};

interface ServiceCardProps {
  title: string;
  Icon: React.ElementType;
  children?: React.ReactNode;
}

const ServiceCard = ({ title, Icon, children }: ServiceCardProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        textAlign: "center",
        backgroundColor: "#fff",
        color: "#1D2A35",
        height: "100%",
      }}
    >
      <CardHeader
        title={<Icon sx={{ fontSize: "60px" }} />}
        sx={{ pb: 0 }}
      ></CardHeader>
      <CardContent sx={{ pt: 0 }}>
        <Typography variant="h6">{title}</Typography>
        {children}
      </CardContent>
    </Card>
  );
};

const ContactUsButton = () => (
  <Button
    size="large"
    variant="contained"
    endIcon={<Email />}
    sx={{
      mt: 3,
      textTransform: "none",
      backgroundColor: "boxfishYellow.main",
      color: "background.default",
      ":hover": {
        backgroundColor: "boxfishYellow.dark",
      },
    }}
  >
    <Obfuscate
      email="support@boxfishsoftware.com"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <Typography variant="h6">Contact Us</Typography>
    </Obfuscate>
  </Button>
);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header showMenu={false} />
      <Box
        sx={{
          position: "relative",
          backgroundColor: "grey.800",
          color: "#fff",
          mb: 4,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `linear-gradient(180deg, rgba(29,42,53,.1) 50%, rgba(29,42,53)), linear-gradient(45deg, rgba(29,42,53,1) 20%, rgba(29,42,53,0.1)), url(${HeroImg})`,
          width: "100vw",
        }}
      >
        <img style={{ display: "none" }} src={HeroImg} />
        <Container maxWidth="lg">
          <Box
            sx={{
              pt: 8,
            }}
          >
            <Box
              sx={{
                p: 2,
                display: "inline-block",
              }}
            >
              <Typography variant="h3">
                Software Development Services
              </Typography>
              <Typography variant="h3">
                For <span style={{ color: "#7AFC9A" }}>Biotech</span>
              </Typography>
              <Typography
                variant="h6"
                sx={{ width: "55%", mt: 2, lineHeight: 1.2 }}
              >
                Boxfish Software can help you build the software you need to
                accelerate your research and development.
              </Typography>
              <ContactUsButton />
            </Box>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <main>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              pt: 1,
              pb: 6,
            }}
          >
            <Grid
              container
              spacing={4}
              sx={{ px: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4}>
                <ServiceCard
                  title="Custom Software Development"
                  Icon={LightbulbCircleIcon}
                >
                  <List>
                    <ListItem>Web development (Python + React)</ListItem>
                    <ListItem>Cloud computing</ListItem>
                    <ListItem>Database management</ListItem>
                    <ListItem>Bioinformatics pipelines</ListItem>
                    <ListItem>Auto-scaling compute clusters</ListItem>
                    <ListItem>Reference data versioning</ListItem>
                    <ListItem>Prototyping</ListItem>
                  </List>
                </ServiceCard>
              </Grid>
              <Grid item xs={4}>
                <ServiceCard
                  title="Off-the-Shelf Solutions"
                  Icon={CloudCircleIcon}
                >
                  <Typography variant="body1" sx={{ textAlign: "left", pl: 2 }}>
                    We can help you choose, deploy, and manage off-the-shelf
                    products, such as:
                  </Typography>
                  <List sx={{ pt: 0, pl: 2 }}>
                    <ListItem>
                      <a href="https://datasette.io/">Datasette</a>
                    </ListItem>
                    <ListItem>
                      <a href="https://www.streamlit.io/">Streamlit</a>
                    </ListItem>
                    <ListItem>
                      <a href="https://retool.com/">Retool</a>
                    </ListItem>
                    <ListItem>
                      <a href="https://www.nocodb.com/">NocoDB</a>
                    </ListItem>
                    <ListItem>
                      <a href="https://pipedream.com/">Pipedream</a>
                    </ListItem>
                    <ListItem>
                      <a href="https://www.appsmith.com/">Appsmith</a>
                    </ListItem>
                  </List>
                </ServiceCard>
              </Grid>
              <Grid item xs={4}>
                <ServiceCard
                  title="Managing Existing Software"
                  Icon={BuildCircleIcon}
                >
                  <List>
                    <ListItem>Improving performance</ListItem>
                    <ListItem>Adding tests/CI</ListItem>
                    <ListItem>Implementing new features</ListItem>
                    <ListItem>Reducing deployment costs</ListItem>
                    <ListItem>Improving security</ListItem>
                    <ListItem>Porting R Shiny to Python + React</ListItem>
                    <ListItem>Moving to a cloud deployment</ListItem>
                  </List>
                </ServiceCard>
              </Grid>
            </Grid>
          </Box>
        </main>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
